<template>
  <div class="page-data">
    <!-- PC端 -->
    <div class="page-banner">
      <div class="banner-mask">
        <div class="banner-title">OneESG数字化平台</div>
        <div class="banner-subtitle" style="margin-top: 20px">
          OneESG致力于提供卓越的可持续发展数字化平台。依托云计算、大数据和AI技
        </div>
        <div class="banner-subtitle">术，结合资深ESG领域专家，为客户提供卓越数字化平台工具。</div>
        <div class="banner-desc">
          OneESG致力于提供卓越的可持续发展数字化平台。依托云计算、大数据和AI技术，结合资深ESG领域专家，为客户提供卓越数字化平台工具。
        </div>
        <div class="one-button" @click="applyPageState = true">申请试用</div>
      </div>
    </div>
    <div class="page-data-container">
      <div class="data-warp">
        <div class="platform-list">
          <div class="list-item" @click="handleClick(s, i)" v-for="(s, i) in platformList" :key="i"
            :class="[i == activeIndex ? 'active' : '']">
            <img v-if="i != activeIndex" class="icnon-img" :src="require(`@/assets/${platformDataList[i].icon2}`)"
              alt="" />
            <img v-else class="icnon-img" :src="require(`@/assets/${platformDataList[i].icon}`)" alt="" />
            {{ s.label }}
          </div>
        </div>

      </div>
      <div class="data-page-warp" v-for="(item, index) in platformDataList" :key="index" :id="item.code">
        <div class="page-warp-item">
          <div class="warp-item-title">
            {{ item.label }}
          </div>
          <div class="warp-item-desc">
            <div class="desc-left">
              <img :src="require(`@/assets/${item.url}`)" alt="">
            </div>
            <div class="desc-right">
              <div class="right-desc-text">{{ item.desc }}</div>
              <div class="one-button" style="margin-left: 75px;" @click="applyPageState = true">申请试用</div>
            </div>
          </div>
          <div class="warp-function">
            <div class="fun-item" :class="[index == 1 ? 'bgf' : '']" v-for="(fun, i) in item.functionList" :key="i">
              <div class="fun-left">
                <img :src="require(`@/assets/${fun.icon}`)" alt="">
              </div>
              <div class="fun-right">
                <div class="fun-label">{{ fun.name }}</div>
                <div class="fun-desc">{{ fun.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 移动端 -->
    <div class="page-moblie">
      <div class="page-m-title">OneESG数字化平台</div>
      <div class="page-m-desc">OneESG致力于提供卓越的可持续发展数字化平台。依托云计算、大数据和AI技
        术，结合资深ESG领域专家，为客户提供卓越数字化平台工具。</div>
      <div class="one-button" @click.stop="applyPageState = true">申请试用</div>
    </div>
    <div class="platform-tab-moblie">
      <div class="tab-items" @click="tabSwtich(s, i)" v-for="(s, i) in platformList" :key="i"
        :class="[moblieIndex == i ? 'active-box' : '']">{{ s.label }}</div>
    </div>
    <div class="data-moblie-warp">
      <div class="warp-item" v-for="(item, index) in platformDataList" :key="index" :id="item.code1">
        <div class="warp-title-moblie">{{ item.label }}</div>
        <div class="warp-title-img"><img :src="require(`@/assets/${item.url}`)" alt=""></div>
        <div class="warp-desc-mobil">
          {{ item.desc }}
        </div>
        <div class="warp-desc-btn" @click="applyPageState = true">申请试用</div>
        <div class="fun-list-moble">
          <div class="fun-item-moblie" v-for="(fun, i) in item.functionList" :key="i"
            :class="[index == 2 && i == item.functionList.length - 1 ? 'FF-S' : '']">
            <div class="fun-molie-left"><img :src="require(`@/assets/${fun.icon}`)" alt=""></div>
            <div class="fun-molie-right">
              <div class="mof-top">{{ fun.name }}</div>
              <div class="mof-top-desc">{{ fun.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContactUS></ContactUS>
    <PageFooter></PageFooter>
    <ApplyPage v-if="applyPageState"></ApplyPage>
  </div>
</template>

<script>
import PageFooter from './footer';
import ApplyPage from './apply.vue';
import ContactUS from './contactUs.vue';
import {
  EventBus
} from "./event.js";
export default {
  name: 'DataPage',
  components: { PageFooter, ApplyPage, ContactUS },
  props: {},
  data() {
    return {
      moblieIndex: 0,
      activeIndex: 0,
      applyPageState: false,
      platformList: [
        { label: 'OneESG价值核算数据平台', code: "aq1", code1: 'aq21', },
        { label: 'OneESG碳智平台', code: "aq2", code1: 'aq221', },
        { label: 'OneESG智能数据披露平台', code: "aq3", code1: 'aq2212', },
      ],
      platformDataList: [
        {
          code: 'aq1',
          code1: 'aq21',
          label: 'OneESG价值核算数据平台',
          desc: '平台覆盖了对 2017 年以来的全部 A 股上市公司和香港上市内地公司的ESG价值核算数据，拥有企业多维度ESG价值分析功能，包含ESG价值核算报表、ESG价值核算报告、投资组合管理工具，助力高校、金融机构等客户将ESG因素纳入可持续投融资研究及决策。',
          url: 'a1b2.png',
          icon: 'icon11.png',
          icon2: "d1.png",
          functionList: [
            {
              name: "ESG价值核算报表",
              desc: "OneESG为企业生成类财务报表的ESG价值核算报表，企业可披露在ESG报告中，同时也为投资者和企业自身提供可验证的、有据可依的、具有可比性的 ESG 价值货币化结果作为决策依据。",
              icon: 'aq1.png'
            },
            {
              name: "ESG价值核算报告",
              desc: "OneESG基于ESG价值核算数据对企业ESG价值进行多维度分析，帮助企业切实了解ESG各议题的风险及机遇价值。支持同行ESG对标，为投资决策提供重要参考。",
              icon: 'aq2.png'
            },
            {
              name: "投资组合管理",
              desc: "OneESG具备强大的投资组合管理功能。可通过丰富的ESG选股因子创建投资组合，实时查看收益。有助于投资者做出更明智的投资决策，研究者进行更深度的组合策略分析。",
              icon: 'aq3.png'
            },
            {
              name: "ESG数据浏览器",
              desc: "OneESG提供的数据浏览，是一款由用户自主选择企业及指标的定制型数据提取工具。用户可自定义提取ESG数据，支持指标及企业的关键词检索，支持模板保存及Excel导出功能。",
              icon: 'aq4.png'
            },
          ]
        },
        {
          code: 'aq2',
          code1: 'aq221',
          label: 'OneESG碳智平台 ',
          desc: 'OneESG碳核算平台由资深碳咨询专家和技术数据团队精心打造，基于国际通用碳核算标准ISO 14064和GHG Protocol，结合人工智能技术，实施数据收集、整理、计算、分析、报告披露，三方认证，为您提供一站式碳排放量化和气候风险评估服务',
          url: '99.png',
          icon: 'icon12.png',
          icon2: "d2.png",
          functionList: [
            {
              name: "多种方式的碳数据采集与监测",
              desc: "利用物联网、大数据、人工智能技术，从智能传感器、边缘网关实时收集与监测碳排放数据，也可以采用人工填报、系统接入等方式批量采集碳数据。",
              icon: 'aw1.png'
            },
            {
              name: "智能碳数据核算引擎",
              desc: "覆盖国内外主流核算标准，同时兼容ISO 14064-1和GHG Protocol标准，内置24个行业碳核算因子，利用强大碳数据核算引擎，自动实时的核算碳排放。",
              icon: 'aw2.png'
            },
            {
              name: "强大气候风险评估工具",
              desc: "依托强大的气候风险评估工具，帮助企业识别不同情景气候风险，量化企业以及利益相关方不同情景，不同时间点下的气候风险。",
              icon: 'aw3.png'
            },
            {
              name: "碳认证与核查",
              desc: "对接国内外主流认证、核查机构以及绿色交易所等机构进行碳认证与核查一站式服务。",
              icon: 'aw4.png'
            },
          ]
        },
        {
          code: 'aq3',
          code1: 'aq2212',
          label: 'OneESG智能数据披露平台',
          desc: '一站式ESG数据披露与智能分析产品。内置多种ESG标准指标体系，紧跟监管要求，实现标准化ESG指标管理。利用人工智能和大数据分析技术，对ESG数据进行深度挖掘和分析，为企业提供智能预警和决策支持。平台致力于为企业提供全面、准确、及时的ESG数据披露服务，帮助企业提升ESG表现，实现可持续发展目标。',
          url: '44.png',
          icon: 'icon13.png',
          icon2: "d3.png",
          functionList: [
            {
              name: "披露指标管理",
              desc: "内置专业咨询团队打造指标体系，可对标SSB，MSCI，标普，上交所，港交所等ESG标准，最大支持10000+指标管理",
              icon: 'ae1.png'
            },
            {
              name: "ESG数据智能填报",
              desc: "强大的智能填报功能，支持跨部门、跨层级填报，审核和管理企业ESG数据。通过质量管理体系对数据进行清洗和校验，确保数据准确性。",
              icon: 'ae2.png'
            },
            {
              name: "风险预警与评估",
              desc: "OneESG基于ESG价值核算数据对企业ESG价值进行多维度分析，帮助企业切实了解ESG各议题的风险及机遇价值。支持同行ESG对标，为投资决策提供重要参考。",
              icon: 'ae3.png'
            },
            {
              name: "数据披露管理",
              desc: "支持合规管理和报告披露流程，帮助企业实现ESG数据的实时监控、汇总与分析。支持多种披露格式和标准，确保数据合规性。支持企业根据自身需求，灵活选择披露方式和内容，同时提供丰富的可视化图表，直观展示ESG数据。",
              icon: 'ae4.png'
            },
          ]
        },
      ],

    };
  },

  async mounted() {
    EventBus.$on("aMsg", (msg) => {
      var SC = document.getElementById(this.getQueryParam(msg, 'se'));
      if (SC) {
        SC.scrollIntoView({ behavior: 'smooth' });
      }
    });
    var element = document.getElementById(this.$route.query.se);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); // 使用平滑滚动
    }

  },
  watch: {
    applyPageState(val) {
      if (val) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    }
  }
  ,
  methods: {
    tabSwtich(s, i) {
      var element = document.getElementById(s.code1);
      console.log(element)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // 使用平滑滚动
      }
      this.moblieIndex = i
    },
    getQueryParam(url, param) {
      const regex = new RegExp('[?&]' + param + '=([^&#]*)');
      const results = regex.exec(url);
      return results ? decodeURIComponent(results[1]) : null;
    },
    handleClick(s, i) {
      var element = document.getElementById(s.code);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // 使用平滑滚动
      }
      this.activeIndex = i;
    },

  },
};
</script>

<style scoped lang="stylus">
@media screen and (min-width: 1024px) {
  .page-data-mobile{
    display: none;
  }
  .platform-tab-moblie{
    display none
  }
  .banner-desc{
    display: none;
  }
  .data-moblie-warp{
    display none
  }
  .page-moblie{
    display none
  }
  }
.page-banner{
  width: 100%;
  height: 400px;
  background: url('../assets/a.png') center center no-repeat;
  background-size cover

}

.banner-title{
  font-size: 34px;
  color: #2B3440;
  padding-top: 94px;
  font-weight bold
}
.banner-desc{
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  text-align: justify;
  margin-top: 25px;
}
 .banner-mask{
    width 1200px
    margin 0 auto
} 
.banner-subtitle{
  color: #2B3440;
  font-size 18px
  line-height: 28px;
}
.data-warp{
  width 1200px;
  margin 0 auto
}
.page-data-container{
  width: 100%;

  min-height 100px
}

.one-container{
  width: 1176px;
  background: #fff;
  margin: 0 auto;
}

.one-title{
  font-size: 40px;
  color: #333;
  font-weight: bold;
  text-align: center;
  padding-top:  116px;
}
  .one-warp{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }
.one-left{
  width: 519px;
  height: 387px;
  img{
    width: 100%;
  }
}
.one{
width: 100%;

}
.one-desc{
  width: 520px;
  font-size: 16px;
  color: #666;
  text-align: justify;
  padding-top: 55px;
}

.one-button{
  width: 210PX;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #F5BA0D;
color: #fff;
cursor: pointer;
font-size: 16px;
border-radius: 8px;
margin-top: 27px;
}



.icon{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #F5BA0D;
  margin-right: 10px;
}

.list-title{
  display: flex;
align-items: center;
font-size: 26px;
color: #F5BA0D;
}
.list-desc{
  margin-top: 22px;
  color: #666;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
}
.one-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.platform-list{
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  border:1px solid  #DEE2E6;
  height: 78px;
  background: #F3F3F6;
}

.list-item:last-child{
  border none
}
.list-item{
  width 33.3%
  text-align center
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: bold;
font-size: 18px;
color: #1B2532;
line-height: 78px;
background: #F3F3F6;
text-align: center;
border-right 1px solid #DEE2E6;
font-style: normal;
}
.platform-center{
  width: 1200px;
  margin: 0 auto;
  height: 420px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
}

.platform-img{
  width: 510px;
  margin-left 22px
  height: 268px;
  margin-top 75px
}
.icnon-img{
  width 30px
  height 30px
  vertical-align: text-bottom;
}

.active{
  background: #0ECB92 !important
  color #fff !important
}

.data-page-warp{
  width 100%;
  background #fff
}

.page-warp-item{
  width 1200px
  margin 0 auto 
  height: 1092px;

}

.data-page-warp:nth-child(3){
  width 100%;
  background: #F4F5F7;
}
.warp-item-title{
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: bold;
font-size: 40px;
color: #1B2532;
line-height: 40px;
text-align: center;
padding-top 60px
}

.warp-item-desc{
  width: 100%;
height: 346px;
background: #0ECB92;
box-sizing border-box
margin-top 60px
display flex
padding 40PX
}

.desc-left{
  width  510px
  height  218px
  img{
    width 100%
  }
}

.right-desc-text{
  width: 500px;
height: 140px;
font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 500;
font-size: 16px;
color: #FFFFFF;
line-height: 30px;
text-align: justify;
font-style: normal;
margin-left 75px
margin-top 20px
}

.warp-function{
  display flex;
  flex-wrap wrap
  justify-content space-between
  margin-top 30px
}
.fun-item{
  width: 585px;
height: 218px;
background: #F2F3F6;
display flex
box-sizing border-box
margin-top 30px
padding 30px
}
.fun-left{
  width 104px
  height 97px
  img{
    width 100%
  }
}
.fun-right{
  width 360px
  margin-left 40px
}

.fun-label{
  font-weight: bold;
font-size: 24px;
color: #000000;
}
.fun-desc{
  margin-top 12px
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 16px;
color: #738093;
line-height: 24px;
text-align: justify;
}

.bgf{
  background #fff !important
}


@media only screen and (max-width: 768px) {
  .page-banner{
  display none
  }
  .page-data-container{
  display none
  }
  }
  
  .page-moblie{
  box-sizing border-box
  width 100%
  height 312px
  background: url('../assets/mp1.png') center center  no-repeat;
  background-size cover
  padding-left 14px
  padding-right 14px
  }
  
  .page-m-title{
  font-weight: bold;
  font-size: 28px;
  color: #2B3440;
  text-align:justify;
  padding-top 44px
  }
  
  .page-m-desc{
  width calc(100% - 14px -14px)
  font-weight: 400;
  font-size: 14px;
  text-align:justify;
  color: #2B3440;line-height: 24px;
  } 

  .data-page-moblie{
    width 100%
    height 70px
    box-sizing border-box
    padding-left 14px
    padding-right 14px
    background #fff
    overflow-x: auto; /* 允许水平滚动 */
    overflow-y: hidden; /* 禁用垂直滚动 */
    white-space: nowrap; /* 防止子元素换行 */
  }
 
  .p1-item{
    float left
    width 200px
  }

  .data-moblie-warp{
    width 100%
    padding 0 14px
    box-sizing border-box
  }

  .warp-item{
    width 100%
  }

  .warp-title-moblie{
    margin-top 20px
    font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: bold;
font-size: 24px;
color: #1B2532;
line-height: 24px;
text-align: center;
  }

  .warp-title-img{

    width 319px 
    height 176px
    margin 0 auto
    margin-top 35px
    img{
      width 100%
    }
  }

  .warp-desc-mobil{
    margin-top 15px
    font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 14px;
color: #657D8D;
line-height: 17px;
text-align: center;
  }

  .warp-desc-btn{
    margin-top 28px
    height: 42px;
background: #FABE0D;
box-shadow: 0px 1 3px 0px rgba(250,190,13,0.2);
border-radius: 3px;
font-weight: 400; 
font-size: 14px;
color: #FFFFFF;
display : flex;
align-items: center;
justify-content :center;
}

.fun-item-moblie{
  display flex
  width 100%
  height: 104px;
background: #F2F3F6;
margin-top 15px
box-sizing border-box
padding 14px
}
.fun-molie-left{
  width 72px 
  height 67px
  img{
    width 100%;
    height 100%;
  }
}

.mof-top{
  font-weight: bold;
font-size: 14px;
color: #000000;
line-height: 21px;
text-align: left;
}

.mof-top-desc{
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 10px;
color: #738093;
line-height: 13px;
text-align: justify;
font-style: normal;
margin-top 7px
}

.fun-molie-right{
  margin-left 11px
  width calc(100% - 72px - 28px )
}
.warp-item{
  padding-bottom 20px
}

.FF-S{
  height 120px
}

.platform-tab-moblie-container{
  width 100%
  box-sizing border-box
  padding 0 14px
}
.platform-tab-moblie{
  width 100%;
  background #fff
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; /* 添加这一行以防止子元素换行 */
}

.tab-items{
  display: inline-block; /* 添加这一行使子元素水平排列 */
  min-width 100px
  height 50px
  background #fff
}

.tab-items{
  font-weight: bold;
font-size: 14px;
color: #1B2532;
line-height 50px
padding 0 20px
}

.platform-tab-mobile::-webkit-scrollbar {
  display: none !important; /* For Webkit browsers (Chrome, Safari) */
}

.active-box{
  color: #0ECB92;
}

</style>
